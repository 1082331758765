import { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";

import { CHAIN_INFO } from "./constants";

import { fromWeiWithDecimals, toWeiWithDecimals } from "./decimals";

export function useStakingRead() {
  const { account, library, chainId } = useWeb3React();

  async function staked(user) {
    let nfts = [];
    if (library && chainId && account && CHAIN_INFO[chainId].staking) {
      let contract = new ethers.Contract(
        CHAIN_INFO[chainId].staking.address,
        CHAIN_INFO[chainId].staking.abi,
        library
      );

      let depositedTokenAmounts = await contract.depositedTokenAmounts(user);

      let contractNFT = new ethers.Contract(
        "0xc6C817cd60E17Fed0AF2A759624e02Dd6c812E64",
        CHAIN_INFO[chainId].mainNFT.abi,
        library
      );

      if (depositedTokenAmounts.toString() > 0) {
        for (var index = 0; index < depositedTokenAmounts.toString(); index++) {
          let tokenId = await contract.depositedTokenIds(user, index);
          let owner = await contractNFT.ownerOf(tokenId.toString());

          if (owner == CHAIN_INFO[chainId].staking.address) {
            nfts.push(tokenId.toString());
          }
        }
      }
    }

    return nfts;
  }

  async function getBalance() {
    if (library && chainId && account && CHAIN_INFO[chainId].staking) {
      let contract = new ethers.Contract(
        CHAIN_INFO[chainId].staking.withdraw,
        CHAIN_INFO[chainId].staking.withdrawAbi,
        library
      );

      let balance = await contract.getBalance();
      return balance.toString();
    }

    return nfts;
  }

  return { staked, getBalance };
}

export function useStakingWrite() {
  const { account, library, chainId } = useWeb3React();

  async function withdraw(tokenId) {
    let contract, tx;

    const signer = library.getSigner();

    contract = new ethers.Contract(
      CHAIN_INFO[chainId].staking.address,
      CHAIN_INFO[chainId].staking.abi,
      signer
    );

    tx = await contract
      .ownerWithdrawNFT("0xc6C817cd60E17Fed0AF2A759624e02Dd6c812E64", tokenId)
      .then(async (transferResult) => {
        await transferResult.wait();
      });
  }

  async function withdrawBalance(amount) {
    let contract, tx;

    const signer = library.getSigner();

    contract = new ethers.Contract(
      CHAIN_INFO[chainId].staking.withdraw,
      CHAIN_INFO[chainId].staking.withdrawAbi,
      signer
    );

    tx = await contract.withdraw(amount).then(async (transferResult) => {
      await transferResult.wait();
    });
  }

  return { withdraw, withdrawBalance };
}
