import React, { useState, useEffect } from "react";

// Chakra imports
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Text,
  CircularProgress,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Progress,
  InputGroup,
  Input,
  InputRightElement,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
// Custom components
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardHeader from "../../components/Card/CardHeader";
import IconBox from "../../components/Icons/IconBox";

import ConnectCard from "../../components/ConnectCard/ConnectCard";
import UnavailableCard from "../../components/UnavailableCard/UnavailableCard";
import SwitchNetworkCard from "../../components/SwitchNetworkCard/SwitchNetworkCard";

// Custom icons
import {
  RocketIcon,
  WalletIcon,
  ClockIcon,
  StatsIcon,
} from "../../components/Icons/Icons.js";

import { FaPlus } from "react-icons/fa";

import { useWeb3React } from "@web3-react/core";

import { useStakingRead, useStakingWrite } from "../../utils/staking";

import { CHAIN_INFO } from "../../utils/constants";

function Staking() {
  const { active, chainId } = useWeb3React();

  const { withdraw, withdrawBalance } = useStakingWrite();

  //fetch staking
  const { staked, getBalance } = useStakingRead();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [nfts, setNFTS] = useState();
  const [loading, setLoading] = useState();
  const [user, setUser] = useState();

  const [balance, setBalance] = useState();

  return active ? (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }} my="26px">
      {CHAIN_INFO[chainId].staking ? (
        <>
          <Card p="16px" my="24px">
            <CardHeader p="12px 5px" mb="12px">
              <Flex direction="column">
                <Text fontSize="lg" fontWeight="bold">
                  Staking
                </Text>
                <Text fontSize="sm" color="gray.500" fontWeight="400">
                  Staking admin
                </Text>
              </Flex>
            </CardHeader>
            <CardBody px="5px">
              <Flex direction="column" w="100%">
                <InputGroup w="80%" mb="15px">
                  <Input
                    variant="outline"
                    minWidth="250px"
                    minHeight="64px"
                    placeholder="Enter user address"
                    borderRadius="16px"
                    px="34px"
                    onChange={(event) => setUser(event.currentTarget.value)}
                  />
                  <InputRightElement w="64px" h="64px">
                    <Button
                      borderRadius="10px"
                      onClick={async () => {
                        setLoading(true);
                        setNFTS();
                        setNFTS(await staked(user));
                        setLoading(false);
                      }}
                    >
                      GET
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {nfts && (
                  <Flex direction="row" w="100%">
                    {Object.keys(nfts).map((nftid) => {
                      return (
                        <Button
                          m="3px"
                          w="100px"
                          borderRadius="10px"
                          onClick={() => {
                            withdraw(nfts[nftid]);
                          }}
                        >
                          {nfts[nftid]}
                        </Button>
                      );
                    })}
                  </Flex>
                )}
                {loading && (
                  <Progress borderRadius="10px" bg="gray.800" isIndeterminate />
                )}
                <Button
                  mt="100px"
                  w="170px"
                  borderRadius="10px"
                  onClick={async () => {
                    setBalance();
                    setBalance(await getBalance());
                    withdrawBalance(balance);
                  }}
                >
                  Withdraw Balance
                </Button>
              </Flex>
            </CardBody>
          </Card>
        </>
      ) : (
        <Flex
          justifyContent="center"
          align="center"
          flexDirection="column"
          mt={{
            md: "5%",
            xl: "5%",
          }}
        >
          <SwitchNetworkCard functionality="Staking" chainAvailable={[1]} />
        </Flex>
      )}
    </Flex>
  ) : (
    <Flex
      justifyContent="center"
      align="center"
      mt={{
        sm: "25%",
        md: "10%",
        xl: "10%",
      }}
    >
      <ConnectCard />
    </Flex>
  );
}

export default Staking;
